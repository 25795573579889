// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Register.css */
.register-page {
  height: 100vh; /* Full height */
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-form {
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}
`, "",{"version":3,"sources":["webpack://./src/Register.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;EACE,aAAa,EAAE,gBAAgB;EAC/B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,2CAA2C;AAC7C","sourcesContent":["/* Register.css */\n.register-page {\n  height: 100vh; /* Full height */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.register-form {\n  border-radius: 10px;\n  padding: 20px;\n  background-color: #fff;\n  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
